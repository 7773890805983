import { Vector2, Group } from "three";
import { soRoom2D } from "../SceneObjects/Room/soRoom2D";
import { soWall2D } from "../SceneObjects/Wall/soWall2D";
import { Vertex } from "./Vertex";
import { Side } from "../../../models/Side";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import VectorUtils from "../../utils/GeometryUtils/VectorUtils";
import { appModel } from "../../../models/AppModel";
import { soFloor2D } from "../SceneObjects/Floor/soFloor2D";
import BoundingBoxUtils from "../../utils/GeometryUtils/BoundingBoxUtils";
import { EPSILON } from "../../consts";
import WallUtils from "../../utils/WallUtils";
import soSpace from "./Space";
import WallOverrides from "../SceneObjects/Wall/wallOverrides";
import { settings } from "../../../entities/settings";
import WallOverrideUtils from "../../utils/WallOverrideUtils";
import WallFuncInfo from "../SceneObjects/Wall/WallFuncInfo";

/* ------------------------------------------------------------------------- */
/*                              Class Definition                             */
/* ------------------------------------------------------------------------- */

/**
 * Manages walls and vertices derived from rooms (soRoom2D objects).
 * Each wall is directional (vertical or horizontal), and each vertex tracks
 * connected edges (up, down, left, right) as well as the number of room corners
 * it represents.
 */
export default class WallManager extends Group {
  /* ----------------------------------------------------------------------- */
  /*                           Private Properties                            */
  /* ----------------------------------------------------------------------- */

  private vertices: Map<string, Vertex>;
  private walls: Map<string, soWall2D>;
  private rooms: Map<string, soRoom2D>;
  private WallCache: string[] = [];
  private roomWallsOverrideCache: Map<string, WallOverrides> = new Map();
  private processedWalls: Set<string> = new Set(); // processed walls set that is used to prevent infinite loop #DCP-1872
  /* ----------------------------------------------------------------------- */
  /*                                Constructor                              */
  /* ----------------------------------------------------------------------- */

  /**
   * Creates a new WallManager instance.
   * @param ParentFloor - Reference to the floor that this wall manager is associated with.
   */
  constructor(private ParentFloor: soFloor2D) {
    super();
    this.vertices = new Map<string, Vertex>();
    this.walls = new Map<string, soWall2D>();
    this.rooms = new Map<string, soRoom2D>();
  }

  /* ----------------------------------------------------------------------- */
  /*                            Public Methods                               */
  /* ----------------------------------------------------------------------- */

  /**
   * Adds a list of rooms to the rooms list and updates the walls and vertices accordingly.
   * @param rooms An array of soRoom2D objects representing the rooms to add.
   */
  public addRooms(rooms: soRoom2D[], updateIntersectedRooms: boolean = true): void {
    try {
      // Add rooms
      rooms.forEach(room => {
        if (!this.rooms.has(room.soId)) {
          this.addRoom(room);
        }
      });

      // Update walls geometry only after all walls have been updated: splitted into additional segments, etc.
      // If do it for each room separately, the walls may not be up to date for WallOverrides
      this.updateWallsGeometry(true);
    } catch (error) {
      console.error("Failed to add rooms: " + (error as Error).message);
    }
  }
  /**
   * Adds a room to the rooms list and updates the walls and vertices accordingly.
   * @param room The soRoom2D object representing the room to add.
   * @throws Error if the room is invalid.
   */
  private addRoom(room: soRoom2D): void {
    try {
      room.removeAllWalls();
      room.wallsIds = [];
      // loading core plan/add romm - reset the processed walls set that is used to prevent infinite loop #DCP-1872
      this.processedWalls.clear();
      this.rooms.set(room.soId, room);

      const boundingBox = room.boundingBoxByModelLine;
      const corners = BoundingBoxUtils.getBoundingBoxCorners2D(boundingBox);
      let dataBoxVertices = room.dataBoxes.map(dataBox => dataBox.getVerticesOnRoomBoundary(boundingBox)).flat();
      // Remove pairs of vertices that are too close to each other
      dataBoxVertices = this.filterCloseVertices(dataBoxVertices, settings.values.parametersSettings.plmFixtureWallSplitTolerance);
      dataBoxVertices = dataBoxVertices.filter(vertex => this.validateVertexDistance(vertex, 3));

      // Update vertices for corners and dataBoxVertices
      this.updateRoomVertices([...corners, ...dataBoxVertices]);

      // Update walls with new edges
      this.updateWallsForBoundingBox(corners);
    } catch (error) {
      throw new Error("Failed to add room: " + (error as Error).message);
    }
  }

  /**
   * Updates vertices for a room by validating distance (if necessary), updating vertex counts,
   * and splitting walls when required.
   *
   * @param vertices - The list of vertices to process.
   * @param validateDistance - Whether to validate the vertex distance before processing.
   */
  private updateRoomVertices(vertices: THREE.Vector2[]): void {
    const set = new Set(vertices);
    for (const vertex of set) {
      const vertexId = Vertex.generateVertexId(vertex);
      let existingVertex = this.vertices.get(vertexId);
      if (!existingVertex) {
        existingVertex = new Vertex(vertex);
        this.vertices.set(vertexId, existingVertex);
      }
      this.updateVertexCount(vertexId, 1);
      // Skip wall splitting for walls belonging to outdoor rooms.

      this.splitWallIfNecessary(existingVertex);
    }
  }

  /**
   * Adds a list of rooms to the rooms list and updates the walls and vertices accordingly.
   * @param rooms An array of soRoom2D objects representing the rooms to add.
   */
  public removeRooms(rooms: soRoom2D[]): void {
    try {
      rooms.forEach(room => {
        room.wallOverrides.forEach(wallOverride => this.roomWallsOverrideCache.set(wallOverride.WallId, wallOverride));
      });
      rooms.forEach(room => {
        if (this.rooms.has(room.soId)) {
          this.removeRoom(room);
          this.updateWallsGeometry(true);
        }
      });
    } catch (error) {
      console.error("Failed to remove rooms: " + (error as Error).message);
    }
  }
  /**
   * Removes a room from the rooms list and updates the walls and vertices accordingly.
   * @param room The soRoom2D object representing the room to remove.
   * @throws Error if the room is invalid or not found.
   */
  private removeRoom(room: soRoom2D): void {
    if (!this.rooms.has(room.soId)) {
      throw new Error(`Room ${room.name} Id:${room.soId} not found in the manager.`);
    }

    try {
      const roomWallIds = [...room.wallsIds];
      const boundingBox = room.boundingBoxByModelLine;
      const corners = BoundingBoxUtils.getBoundingBoxCorners2D(boundingBox);
      let dataBoxVertices = room.dataBoxes.map(dataBox => dataBox.getVerticesOnRoomBoundary(boundingBox)).flat();

      // Remove pairs of vertices that are too close to each other
      dataBoxVertices = this.filterCloseVertices(dataBoxVertices, settings.values.parametersSettings.plmFixtureWallSplitTolerance);

      this.rooms.delete(room.soId);

      const vertices: Set<Vector2> = new Set<Vector2>();
      for (const vertex of dataBoxVertices) {
        vertices.add(vertex);
      }
      // Update vertex counts for corners and dataBoxVertices
      this.decrementVertexCounts([...corners, ...dataBoxVertices]);

      const remainingWalls = roomWallIds.filter(wallId => this.walls.has(wallId));
      const invalidWalls = remainingWalls.filter(wallID => !this.verifyWallValidById(wallID));
      invalidWalls.forEach(wallId => this.RemoveWallById(wallId));
    } catch (error) {
      console.log("Failed to remove room: " + error);
    }
  }

  updateWallOverrides(room: soRoom2D) {
    const newWallMap = new Map<string, soWall2D>();
    const newOldWallMap = new Map<string, soWall2D>();
    [...room.wallsSideMap.keys()].forEach(ws => {
      const wall = this.getWallById(ws);
      newWallMap.set(ws, wall);
      newOldWallMap.set(ws, wall.DeepCopy());
    });

    // Getting existing overrides
    const oldWallOverrides = WallOverrideUtils.getExistingWallOverrides(room);

    // if the room is new or just loaded, then we write current overrides to the old overrides
    if (!room.oldWallsMap || room.oldWallsMap.size === 0) {
      room.oldWallsMap = newOldWallMap;
      [...newWallMap.values()].forEach(w => {
        WallOverrideUtils.updateWallOverrides(room, w, w, oldWallOverrides, false);
      });
      [...newWallMap.values()].forEach(w => w.updateGeometry());
      return;
    }

    // Go through each side of the room separately
    Object.keys(Side).forEach(key => {
      const roomSide = Side[key];

      const wallAxis = Array.from(newWallMap.values())[0].isVertical ? "y" : "x";

      const newMaxEnd = Math.max(...[...newWallMap.values()].map(w => w.end[wallAxis]));
      const oldMaxEnd = Math.max(...[...room.oldWallsMap.values()].map(w => w.end[wallAxis]));

      // Sort walls in ascending order by coordinates
      const oldWallsByRoomSide = [...room.oldWallsMap.values()]
        .filter(w => w.parentRoomSides?.find(rs => rs.RoomId === room.soId && rs.RoomSide === roomSide))
        .sort((w1, w2) => (newMaxEnd === oldMaxEnd ? w2.start[wallAxis] - w1.start[wallAxis] : w1.start[wallAxis] - w2.start[wallAxis]));

      const curOldWallOverrides = oldWallOverrides?.filter(owo => oldWallsByRoomSide.find(w => w.wallId === owo.WallId));
      if (!curOldWallOverrides?.length) return;

      const newWallsByRoomSide = [...newWallMap.values()]
        .filter(w => w.parentRoomSides?.find(rs => rs.RoomId === room.soId && rs.RoomSide === roomSide))
        .sort((w1, w2) => (newMaxEnd === oldMaxEnd ? w2.start[wallAxis] - w1.start[wallAxis] : w1.start[wallAxis] - w2.start[wallAxis]));

      // Arrange walls by FuncCode
      const oldWallsByFuncCode = WallOverrideUtils.orderWallsByFuncCode(oldWallsByRoomSide);
      const newWallsByFuncCode = WallOverrideUtils.orderWallsByFuncCode(newWallsByRoomSide);

      // If FuncCode in the arrays match, then we overwrite them as they are in order
      if (WallOverrideUtils.haveIdenticalOrder(oldWallsByFuncCode, newWallsByFuncCode)) {
        newWallsByFuncCode.forEach((newWallFuncInfo, idx) => {
          const oldArray = oldWallsByFuncCode[idx].Walls;
          WallOverrideUtils.updateWallArrays(room, oldArray, newWallFuncInfo.Walls, curOldWallOverrides);
        });
      } else if (oldWallsByFuncCode.length !== newWallsByFuncCode.length) {
        // Looking for the smallest common array. Delete the others items
        const funcCodesByStart = WallOverrideUtils.getWallsBySameOrder(oldWallsByFuncCode, newWallsByFuncCode, 0, 1);
        const funcCodesByEnd = WallOverrideUtils.getWallsBySameOrder(oldWallsByFuncCode, newWallsByFuncCode, -1, 1);

        let finalFuncCodeArray: WallFuncInfo[][] = [];
        if (!funcCodesByStart.length || (funcCodesByStart.length && funcCodesByEnd.length && funcCodesByStart[1].length < funcCodesByEnd[1].length)) {
          finalFuncCodeArray = funcCodesByEnd;
        } else {
          finalFuncCodeArray = funcCodesByStart;
        }

        if (finalFuncCodeArray.length === 4) {
          const oldFuncCodeArray: WallFuncInfo[] = finalFuncCodeArray[0];
          const newFuncCodeArray: WallFuncInfo[] = finalFuncCodeArray[1];

          newFuncCodeArray.forEach((newWallFuncInfo, idx) => {
            const oldArray = oldFuncCodeArray[idx].Walls;
            if (!oldArray) return;
            WallOverrideUtils.updateWallArrays(room, oldArray, newWallFuncInfo.Walls, curOldWallOverrides);
          });

          // // Add new wall overrides for walls that in the tail
          if (finalFuncCodeArray[2].length > 1) {
            finalFuncCodeArray[2].forEach(newWallFuncInfo => {
              newWallFuncInfo.Walls.forEach(w => {
                if (w.parentRoomIds.length !== 1 || w.parentRoomIds[0] !== room.soId) return;
                WallOverrideUtils.updateWallArrays(room, newFuncCodeArray[0].Walls, newWallFuncInfo.Walls, curOldWallOverrides);
              });
            });
          }

          if (finalFuncCodeArray[3].length > 1) {
            finalFuncCodeArray[3].forEach(newWallFuncInfo => {
              newWallFuncInfo.Walls.forEach(w => {
                if (w.parentRoomIds.length !== 1 || w.parentRoomIds[0] !== room.soId) return;
                WallOverrideUtils.updateWallArrays(room, newFuncCodeArray[-1].Walls, newWallFuncInfo.Walls, curOldWallOverrides);
              });
            });
          }
        } else {
          // If the walls are too chaotically mixed, then simply delete all old overrides and modifications
          oldWallsByRoomSide.forEach(w => WallOverrideUtils.deleteWallOverrides(room, w, curOldWallOverrides));
        }
      }
    });

    // Update geometry of all walls
    [...newWallMap.values()].forEach(w => w.updateGeometry());

    room.oldWallsMap = newOldWallMap;
  }

  /**
   * Decrements the count for a list of vertices.
   * This can include corners, dataBox vertices, or other types of vertices.
   *
   * @param vertices - The list of vertices to process.
   */
  private decrementVertexCounts(vertices: THREE.Vector2[]): void {
    try {
      const vertexIds = new Set(vertices.map(vertex => Vertex.generateVertexId(vertex)));
      for (const vertexId of vertexIds) {
        this.updateVertexCount(vertexId, -1);
      }
    } catch (error) {
      console.log("Error decrementing vertex counts: " + error);
    }
  }

  /**
   * Filters out pairs of vertices that are too close to each other from the given array.
   * First identifies all close vertices, then removes them.
   *
   * @param vertices - The array of vertices to filter.
   * @param threshold - The distance threshold for removal.
   * @returns {THREE.Vector2[]} - A filtered array of vertices.
   */
  private filterCloseVertices(vertices: THREE.Vector2[], threshold: number): THREE.Vector2[] {
    const toRemove = new Set<number>(); // Indices of vertices to remove

    // Step 1: Identify pairs of close vertices
    for (let i = 0; i < vertices.length; i++) {
      for (let j = i + 1; j < vertices.length; j++) {
        if (this.isWithinThreshold(vertices[i], vertices[j], threshold)) {
          toRemove.add(i);
          toRemove.add(j);
        }
      }
    }

    // Step 2: Filter out identified vertices
    return vertices.filter((_, index) => !toRemove.has(index));
  }

  /**
   * Checks if two vertices are within the given distance threshold.
   *
   * @param vertex1 - The first vertex.
   * @param vertex2 - The second vertex.
   * @param threshold - The distance threshold.
   * @returns {boolean} - True if the vertices are within the threshold, otherwise false.
   */
  private isWithinThreshold(vertex1: THREE.Vector2, vertex2: THREE.Vector2, threshold: number): boolean {
    const distanceX = Math.abs(vertex1.x - vertex2.x);
    const distanceY = Math.abs(vertex1.y - vertex2.y);

    return distanceX < threshold && distanceY < threshold;
  }

  /**
   * Validates if a given vertex is farther than a specified distance from all vertices in the collection.
   * Checks the distance in the x and y directions separately.
   *
   * @param vertex - The vertex to validate.
   * @param threshold - The distance threshold in x and y directions.
   * @returns {boolean} - Returns true if the vertex is farther than the threshold from all other vertices, false otherwise.
   */
  private validateVertexDistance(vertex: THREE.Vector2, threshold: number): boolean {
    for (const existingVertex of this.vertices.values()) {
      if (this.isWithinThreshold(vertex, existingVertex.point, threshold)) {
        return false; // The vertex is within the threshold of an existing vertex
      }
    }

    return true; // The vertex is valid (not within the threshold of any existing vertex)
  }

  /**
   * Checks whether this manager contains the specified room.
   * @param roomId The ID of the room to check.
   * @returns True if the room exists in the manager, false otherwise.
   */
  public HasRoom(roomId: string): boolean {
    return this.rooms.has(roomId);
  }

  /**
   * Returns the current list of walls.
   * @returns An array of soWall2D objects.
   */
  public getWalls(): soWall2D[] {
    return Array.from(this.walls.values());
  }

  /**
   * Retrieves a specific wall by its ID.
   * @param id The ID of the wall to retrieve.
   * @returns The soWall2D object or undefined if not found.
   */
  public getWallById(id: string): soWall2D | undefined {
    return this.walls.get(id);
  }

  /**
   * Checks if a wall exists by its ID.
   * @param id The wall ID.
   * @returns True if the wall exists, false otherwise.
   */
  public hasWall(id: string): boolean {
    return this.walls.has(id);
  }

  /**
   * Retrieves a specific vertex by its ID.
   * @param id The ID of the vertex to retrieve.
   * @returns The Vertex object or undefined if not found.
   */
  public getVertexById(id: string): Vertex | undefined {
    return this.vertices.get(id);
  }

  /**
   * Retrieves multiple walls by their IDs.
   * @param ids The array of wall IDs to retrieve.
   * @returns An array of soWall2D objects.
   */
  public getWallsByIds(ids: string[]): soWall2D[] {
    return ids.map(id => this.walls.get(id)).filter((wall): wall is soWall2D => wall !== undefined);
  }

  /**
   * Removes a wall from the manager by its soWall2D object.
   * @param wall The soWall2D object to remove.
   * @returns True if the wall was removed, false otherwise.
   */
  public RemoveWall(wall: soWall2D): boolean {
    if (!wall) return false;
    try {
      this.removeWallFromVertices(wall);
      // If you need to remove it from a cache: this.removeFromWallCache(wall);
      wall.parentRoomIds?.forEach(id => {
        (this.parent as soFloor2D).getRoomById(id)?.removeWall(wall.wallId);
      });

      this.walls.delete(wall.wallId);
      this.remove(wall);
      GeometryUtils.disposeObject(wall);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Removes a wall from the manager by its wallId.
   * @param wallId The ID of the wall to remove.
   * @returns True if the wall was removed, false otherwise.
   */
  public RemoveWallById(wallId: string): boolean {
    const wall = this.getWallById(wallId);
    return this.RemoveWall(wall);
  }

  /**
   * Adds a wall to the walls map and updates the vertices accordingly.
   * @param wall The soWall2D object to add.
   */
  public addWall(wall: soWall2D): void {
    const wallId = wall.wallId;
    this.addToWallCache(wall);

    if (!this.walls.has(wallId)) {
      this.addWallToVertices(wall);
      this.walls.set(wallId, wall);
      this.add(wall);
    }
    this.updateWallFunction(wall);
  }

  /**
   * Links a wall to its start and end vertices.
   * @param wall The wall to link.
   */
  public addWallToVertices(wall: soWall2D): void {
    const startVertexId = Vertex.generateVertexId(wall.start);
    const endVertexId = Vertex.generateVertexId(wall.end);

    const startVertex = this.vertices.get(startVertexId);
    const endVertex = this.vertices.get(endVertexId);

    if (startVertex) {
      this.addEdgeToVertex(startVertex, wall);
    }
    if (endVertex) {
      this.addEdgeToVertex(endVertex, wall);
    }
  }

  /**
   * Removes a wall reference from its start and end vertices.
   * @param wall The wall to remove.
   */
  public removeWallFromVertices(wall: soWall2D): void {
    const startVertexId = Vertex.generateVertexId(wall.start);
    const endVertexId = Vertex.generateVertexId(wall.end);

    const startVertex = this.vertices.get(startVertexId);
    const endVertex = this.vertices.get(endVertexId);

    if (startVertex) {
      startVertex.edges = Object.fromEntries(Object.entries(startVertex.edges).filter(([, value]) => value !== wall.wallId));
    }
    if (endVertex) {
      endVertex.edges = Object.fromEntries(Object.entries(endVertex.edges).filter(([, value]) => value !== wall.wallId));
    }
  }

  /**
   * Updates the wall's external/internal status based on its intersection with rooms.
   * Checks if the midpoint of the given wall intersects with any room's bounding box.
   * @param wall The wall to be updated.
   */
  public updateWallFunction(wall: soWall2D, tolerance = 0.001): void {
    const intersectingRooms = Array.from(this.rooms.values()).filter(room => {
      const midpoint3D = VectorUtils.Vector2ToVector3(wall.getWallMidpoint());
      return (
        GeometryUtils.isPointInsideBoundingBox(midpoint3D, room.boundingBoxByModelLine, tolerance) &&
        GeometryUtils.isPointOnBoundingBoxPerimeter(midpoint3D, room.boundingBoxByModelLine, tolerance)
      );
    });

    if (intersectingRooms.length === 0) {
      this.RemoveWall(wall);
      return;
    }

    intersectingRooms.forEach(room => {
      room.addWall(wall);
    });
    wall.setParentRooms(intersectingRooms);
  }

  /**
   * Adds a wall to the internal cache.
   * @param wall The wall to cache.
   */
  public addToWallCache(wall: soWall2D): void {
    if (!this.WallCache.includes(wall.wallId)) {
      this.WallCache.push(wall.wallId);
    }
  }

  /**
   * Adds a wall to the internal cache by wall ID.
   * @param wallId The wall ID to add.
   */
  public addToWallCacheById(wallId: string): void {
    const wall = this.getWallById(wallId);
    if (wall) {
      this.addToWallCache(wall);
    }
  }

  /**
   * Removes a wall from the internal cache.
   * @param wall The wall to remove from cache.
   */
  public removeFromWallCache(wall: soWall2D): void {
    if (this.WallCache.includes(wall.wallId)) {
      this.WallCache = this.WallCache.filter(id => id !== wall.wallId);
    }
  }

  /**
   * Updates the wall geometry for either just cached walls or all walls.
   * @param wallCacheOnly If true, only walls in the WallCache are updated. Otherwise, update all walls.
   */
  public updateWallsGeometry(wallCacheOnly: boolean = false): void {
    if (wallCacheOnly) {
      this.updateGeometryByWallIds(this.WallCache);
      this.WallCache = [];
    } else {
      this.updateGeometryByWallIds(Array.from(this.walls.keys()));
    }
  }

  /**
   * Updates the geometry of walls based on their IDs.
   * Track updated walls to prevent reprocessing, avoid infinite loops.
   * @param wallsIds The IDs of the walls to update.
   * @param updateRoomBoundary If true, updates the room boundary after updating walls.
   * @param toUpdateWallOverrides If true, updates wall overrides after updating walls.
   */
  public updateGeometryByWallIds(wallsIds: string | string[], updateRoomBoundary: boolean = true, toUpdateWallOverrides = true): void {
    try {
      const rooms: Set<soRoom2D> = new Set();
      const ids = Array.isArray(wallsIds) ? wallsIds : [wallsIds];

      this.getWallsByIds(ids).forEach(wall => {
        if (this.processedWalls.has(wall.wallId)) return;
        this.processedWalls.add(wall.wallId);

        wall.parentRoomIds.forEach(roomId => {
          rooms.add(this.rooms.get(roomId));
        });
      });

      if (updateRoomBoundary) this.updateRoomsNetBoundary(Array.from(rooms), toUpdateWallOverrides);
    } catch (error) {
      console.error("Failed update geometry by WallIds: " + (error as Error).message);
    }
  }

  updateRoomsNetBoundary(rooms: soRoom2D[], toUpdateWallOverrides = true) {
    rooms.forEach(room => {
      if (room) {
        if (toUpdateWallOverrides) {
          this.updateWallOverrides(room);
        }
        room.calculateNetRoomBoundaryOffsets();
        room.updateNetBoundary();
      }
    });
  }

  updateNetRoomBoundaryOffsets() {
    this.rooms.forEach(room => {
      if (room) {
        room.calculateNetRoomBoundaryOffsets();
      }
    });
  }

  /**
   * Verifies if a wall is valid by checking if its midpoint intersects with any room bounding box.
   * @param wall The wall to verify.
   * @returns True if the wall is valid, false otherwise.
   */
  public verifyWallValid(wall: soWall2D): boolean {
    const wallMidpoint = wall.getWallMidpoint();
    return Array.from(this.rooms.values()).some(room =>
      GeometryUtils.isPointInsideBoundingBox(VectorUtils.Vector2ToVector3(wallMidpoint), room.boundingBoxByModelLine)
    );
  }

  /**
   * Verifies if a wall is valid by its ID.
   * @param wallId The wall ID to verify.
   * @returns True if the wall is valid, false otherwise.
   */
  public verifyWallValidById(wallId: string): boolean {
    const wall = this.getWallById(wallId);
    return wall ? this.verifyWallValid(wall) : false;
  }

  /**
   * Returns the string array of vertex IDs that define a given wall.
   * @param wallId The wall ID string (format: "vertexId1$vertexId2").
   * @returns An array containing the start and end vertex IDs.
   */
  public getVerticesIdsFromWallId(wallId: string): string[] {
    return wallId.split("$");
  }

  /**
   * Returns the current list of vertices in the manager.
   * @returns An array of Vertex objects.
   */
  public getVertices(): Vertex[] {
    return Array.from(this.vertices.values());
  }

  /**
   * Returns the keys (IDs) of the current list of vertices.
   * @returns An array of vertex IDs.
   */
  public getVerticesKeys(): string[] {
    return Array.from(this.vertices.keys());
  }

  /**
   * Returns the keys (IDs) of the current list of walls.
   * @returns An array of wall IDs.
   */
  public getWallsKeys(): string[] {
    return Array.from(this.walls.keys());
  }

  /**
   * Creates a string that can be used to export the wall geometry (e.g., for Rhino).
   * @returns A string representation of wall start/end coordinates.
   */
  public getWallsForRhino(): string {
    let result = "";
    for (const wall of this.walls.values()) {
      result += `${wall.start.x},${wall.start.y},0` + `$` + `${wall.end.x},${wall.end.y},0\n`;
    }
    return result;
  }

  /**
   * Creates and returns an array of `soSpace` objects representing enclosed spaces
   * formed from the outer path of room walls, excluding the shared internal walls. Each space is defined by the walls that enclose it.
   * The contour line (polyline) of each space is ensured to be in clockwise direction.
   * @returns {soSpace[]} An array of `soSpace` objects, each representing an enclosed space.
   */
  public createEnclosedSpacesFromExternalWalls(roomIds: string[]): soSpace[] {
    // Map of free external walls, keyed by their unique wallId
    const freeOuterPathWalls = new Map<string, soWall2D>(
      this.getWalls()
        // Include only those that assoicated with at least one of the specified room IDs
        .filter(wall => wall.parentRoomIds.some(id => roomIds.includes(id)))
        // Include walls that either have exactly one parent room ID (which makes them external) or do not have all their parent room IDs included in the specified room IDs
        .filter(wall => wall.parentRoomIds.length === 1 || !wall.parentRoomIds.every(id => roomIds.includes(id)))
        .map(wall => [wall.wallId, wall]) // Create a map of wallId to wall object
    );

    // Map to store used external walls to prevent reprocessing
    const usedExternalWalls: Map<string, soWall2D> = new Map();

    // Array to store the resulting enclosed spaces
    const enclosedSpaces: soSpace[] = [];

    // Process until all external walls are handled
    while (freeOuterPathWalls.size > 0) {
      // Get the first wall from the map of free external walls
      const firstWall = freeOuterPathWalls.values().next().value as soWall2D;

      // If no wall is found, exit the loop (edge case)
      if (!firstWall) break;

      // Create a new space object to represent the enclosed area
      const space = new soSpace();
      let currentWall = firstWall;

      // Traverse connected external walls to define the boundary of the space
      do {
        const wallId = currentWall.wallId;

        // Mark the current wall as used
        usedExternalWalls.set(wallId, currentWall);
        freeOuterPathWalls.delete(wallId);

        // Add the current wall to the space's contour
        space.addContourWall(currentWall);

        // Add rooms contained by the current wall
        space.addContainedRooms([...currentWall.parentRoomIds]);

        // Get all connected walls at the start and end vertices of the current wall (this doesn't ensure clockwise direction of the walls!)
        const startEdgeIds = currentWall.WallStartVertex?.getAllEdgesIds() || [];
        const endEdgeIds = currentWall.WallEndVertex?.getAllEdgesIds() || [];

        // Combine the two arrays into a single array with unique edge IDs.
        // The algorithm works when preserving the order by end edges first and then start edges.
        const uniqueEdgeIds = Array.from(new Set([...endEdgeIds, ...startEdgeIds]));

        // Get the connected walls using the unique edge IDs.
        const connectedWalls = uniqueEdgeIds.length ? this.getWallsByIds(uniqueEdgeIds) : [];

        // Find the next external wall to continue the loop
        const nextWall = connectedWalls.find(w => freeOuterPathWalls.has(w.wallId));

        // Add all connected internal walls to the space
        connectedWalls.filter(w => !w.isExternal).forEach(w => space.addinternalWall(w));

        // Move to the next external wall
        currentWall = nextWall;
      } while (currentWall);

      // If the space has at least 4 walls in its contour (the minimum required to form a closed room), add the space to the list of enclosed spaces
      if (space.contour.length >= 4) {
        space.ensureClockwiseContour();
        enclosedSpaces.push(space);
      }
    }

    // Return the array of enclosed spaces
    return enclosedSpaces;
  }

  /* ----------------------------------------------------------------------- */
  /*                           Private Methods                               */
  /* ----------------------------------------------------------------------- */

  /**
   * Adjusts the vertexCount of the vertex by the given delta and removes the vertex if vertexCount reaches zero.
   * @param vertexId The ID of the vertex to update.
   * @param delta The amount to adjust the cornerCount by.
   * @throws Error if the vertex does not exist.
   */
  private updateVertexCount(vertexId: string, delta: number): void {
    const vertex = this.vertices.get(vertexId);

    if (!vertex) {
      console.warn(`Vertex with ID ${vertexId} does not exist. Skipping update.`);

      return;
    }
    if (delta > 0) {
      vertex.incrementVertexCount();
    } else {
      vertex.decrementVertexCount();
    }

    if (vertex.getVertexCount() <= 0) {
      // Remove the vertex entirely
      this.removeEdgesFromVertex(vertex);
      this.vertices.delete(vertexId);
    } else {
      // Update walls connected to this vertex
      for (const edgeId of Object.values(vertex.edges)) {
        const edgeWall = this.getWallById(edgeId);
        if (edgeWall) {
          this.addToWallCache(edgeWall);
          this.updateWallFunction(edgeWall);
        }
      }
    }
  }

  /**
   * Splits a wall if the given vertex lies on it (but not on its edges).
   * @param vertex The vertex to check.
   */
  private splitWallIfNecessary(vertex: Vertex): void {
    for (const wall of this.walls.values()) {
      if (wall.isPointOnWall(vertex.point) && !wall.isPointOnWallEdges(vertex.point)) {
        this.splitWall(wall, vertex);
        break;
      }
    }
  }

  /**
   * Splits an existing wall into two walls at the location of a given vertex.
   * @param wall The wall to split.
   * @param vertex The vertex where the split occurs.
   * @returns The new wall IDs [wall1, wall2].
   */
  private splitWall(wall: soWall2D, vertex: Vertex): string[] {
    if (!(wall.isPointOnWall(vertex.point) && !wall.isPointOnWallEdges(vertex.point))) {
      return [];
    }

    // Remove the original wall
    if (this.walls.has(wall.wallId)) this.RemoveWall(wall);

    const startVertexId = Vertex.generateVertexId(wall.start);
    const endVertexId = Vertex.generateVertexId(wall.end);
    const vertexId = vertex.id;

    const wall1Id = WallUtils.generateWallId(startVertexId, vertexId);
    const wall2Id = WallUtils.generateWallId(vertexId, endVertexId);

    const wall1 = new soWall2D(wall.start.clone(), vertex.point.clone());
    const wall2 = new soWall2D(vertex.point.clone(), wall.end.clone());

    this.addWall(wall1);
    this.addWall(wall2);

    return [wall1Id, wall2Id];
  }

  /**
   * For a wall with the given ID, splits it into multiple smaller walls based on additional vertices.
   * @param wallId The ID of the wall to split.
   * @param verticesIds The IDs of the vertices that define split points on the wall.
   */
  private splitWallWithVertices(wallId: string, verticesIds: string[]): void {
    const mergedIds = [...this.getVerticesIdsFromWallId(wallId), ...verticesIds];
    const wallVerticesIds = Vertex.sortVertexIds(mergedIds);

    for (let i = 0; i < wallVerticesIds.length - 1; i++) {
      const startVertexId = wallVerticesIds[i];
      const endVertexId = wallVerticesIds[i + 1];

      const newWallId = WallUtils.generateWallId(startVertexId, endVertexId);
      if (!this.hasWall(newWallId)) {
        const start = this.getVertexById(startVertexId)?.point.clone();
        const end = this.getVertexById(endVertexId)?.point.clone();

        if (start && end) {
          const newWall = new soWall2D(start, end);
          this.addWall(newWall);
        }
      } else {
        const existingWall = this.getWallById(newWallId);
        this.updateWallFunction(existingWall);
        // if snapping walls splits the wall into segements and one of them is outdoor - create geometry
        if (existingWall && (existingWall.WallSides.left?.RoomCategoryName === "outdoor" || existingWall.WallSides.right?.RoomCategoryName === "outdoor")) {
          existingWall.HasGeometry = true;
          existingWall.CreateWallGeometry();
        }
      }
    }
  }

  /**
   * Merges adjacent walls connected to the given vertex if they are collinear.
   * @param vertexId The ID of the vertex around which walls might be merged.
   */
  private mergeWalls(vertexId: string): void {
    const vertex = this.vertices.get(vertexId);
    if (vertex) {
      for (const dir of Object.keys(Side)) {
        const oppositeDir = this.getOppositeDirection(dir);
        const wall1 = this.walls.get(vertex.edges[dir]);
        const wall2 = this.walls.get(vertex.edges[oppositeDir]);

        if (wall1 && wall2) {
          if (WallUtils.areWallsCollinear(wall1, wall2)) {
            // Sort walls so we combine in a consistent order
            const wallsToMerge = [wall1, wall2].sort((a, b) => {
              if (a.isVertical) {
                return a.start.y - b.start.y;
              }
              return a.start.x - b.start.x;
            });

            const w1 = wallsToMerge[0];
            const w2 = wallsToMerge[1];
            this.RemoveWall(w1);
            this.RemoveWall(w2);

            const startPoint = VectorUtils.areVectors2Equal(w1.start, vertex.point) ? w1.end : w1.start;
            const endPoint = VectorUtils.areVectors2Equal(w2.start, vertex.point) ? w2.end : w2.start;
            const startVertexId = Vertex.generateVertexId(startPoint);
            const endVertexId = Vertex.generateVertexId(endPoint);

            const newWallId = WallUtils.generateWallId(startVertexId, endVertexId);
            const newWall = new soWall2D(startPoint.clone(), endPoint.clone());
            this.addWall(newWall);

            // Update edges on vertices
            const startVertex = this.vertices.get(startVertexId);
            const endVertex = this.vertices.get(endVertexId);
            if (startVertex) {
              this.addEdgeToVertex(startVertex, newWall);
            }
            if (endVertex) {
              this.addEdgeToVertex(endVertex, newWall);
            }
          }
        }
      }
    }
  }

  /**
   * Adds an edge (wall) to a vertex in the appropriate direction.
   * Suggestion: Could be moved to a geometry or wall utility file if desired.
   * @param vertex The vertex to add the edge to.
   * @param wall The wall to add.
   */
  private addEdgeToVertex(vertex: Vertex, wall: soWall2D): void {
    const direction = this.getDirectionFromWall(vertex, wall);
    if (direction) {
      vertex.addWallIdBySide(direction, wall.wallId);
    }
  }

  /**
   * Determines the opposite direction of a given side.
   * @param side The original direction.
   * @returns The opposite side.
   */
  private getOppositeDirection(side: string): Side {
    switch (side) {
      case Side.top:
        return Side.bottom;
      case Side.bottom:
        return Side.top;
      case Side.left:
        return Side.right;
      case Side.right:
        return Side.left;
      default:
        return null;
    }
  }

  /**
   * Determines the direction of a wall from a given vertex's perspective.
   * @param vertex The vertex used as a reference point.
   * @param wall The wall to analyze.
   * @returns The side (top, bottom, left, right) if determinable, otherwise null.
   */
  private getDirectionFromWall(vertex: Vertex, wall: soWall2D): Side {
    const wallStartVertex = this.vertices.get(Vertex.generateVertexId(wall.start));
    const wallEndVertex = this.vertices.get(Vertex.generateVertexId(wall.end));
    if (!wallStartVertex || !wallEndVertex) {
      return null;
    }

    if (wallStartVertex.id === vertex.id) {
      if (Math.abs(wallEndVertex.point.x - vertex.point.x) < EPSILON) {
        return wallEndVertex.point.y > vertex.point.y ? Side.top : Side.bottom;
      } else if (Math.abs(wallEndVertex.point.y - vertex.point.y) < EPSILON) {
        return wallEndVertex.point.x > vertex.point.x ? Side.right : Side.left;
      }
    } else if (wallEndVertex.id === vertex.id) {
      if (Math.abs(wallStartVertex.point.x - vertex.point.x) < EPSILON) {
        return wallStartVertex.point.y > vertex.point.y ? Side.top : Side.bottom;
      } else if (Math.abs(wallStartVertex.point.y - vertex.point.y) < EPSILON) {
        return wallStartVertex.point.x > vertex.point.x ? Side.right : Side.left;
      }
    }
    return null;
  }

  /**
   * Removes all edges connected to the given vertex, potentially merging walls if they are collinear.
   * @param vertex The vertex whose edges should be removed.
   */
  private removeEdgesFromVertex(vertex: Vertex): void {
    this.mergeWalls(vertex.id);
    for (const dir of Object.keys(Side)) {
      const wallId = vertex.edges[dir.toLowerCase()];
      if (wallId) {
        this.RemoveWallById(wallId);
      }
    }
  }

  /**
   * Updates the walls based on the bounding box corners of a room.
   * Splits walls if there are intersecting vertices.
   * @param corners An array of 2D corners representing the bounding box.
   */
  private updateWallsForBoundingBox(corners: Vector2[]): void {
    const vertexIds = corners.map(corner => Vertex.generateVertexId(corner));
    const pairs = [
      [0, 1], // Left edge
      [1, 2], // Top edge
      [3, 2], // Right edge
      [0, 3], // Bottom edge
    ];

    for (const [startIndex, endIndex] of pairs) {
      const startVertexId = vertexIds[startIndex];
      const endVertexId = vertexIds[endIndex];
      const wallId = WallUtils.generateWallId(startVertexId, endVertexId);

      if (!this.walls.has(wallId)) {
        const startVertex = this.vertices.get(startVertexId);
        const endVertex = this.vertices.get(endVertexId);

        if (startVertex && endVertex) {
          const wall = new soWall2D(startVertex.point, endVertex.point);
          const intersectingVertices = Array.from(this.vertices.values()).filter(
            v => v.id !== startVertexId && v.id !== endVertexId && wall.isPointOnWall(v.point)
          );

          if (intersectingVertices.length > 0) {
            this.splitWallWithVertices(
              wallId,
              intersectingVertices.map(v => v.id)
            );
          } else {
            this.addWall(wall);
            // If you want to explicitly connect end vertices here, you could do:
            // this.addEdgeToVertex(endVertex, wall);
          }
        }
      } else {
        // Update the function of existing wall and add to cache
        this.updateWallFunction(this.walls.get(wallId));
        this.addToWallCacheById(wallId);
      }
    }
  }
}
