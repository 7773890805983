import * as THREE from "three";
import { soOpening } from "../Openings/soOpening";
import { FuncCode, WallType } from "../../../../entities/catalogSettings/types";
import { Side } from "../../../../models/Side";
import { soGroup } from "../soGroup";
import { Vertex } from "../../graph/Vertex";
import { catalogSettings } from "../../../../entities/catalogSettings";
import { appModel } from "../../../../models/AppModel";
import GeometryUtils from "../../../utils/GeometryUtils/GeometryUtils";
import WallUtils from "../../../utils/WallUtils";
import SceneUtils from "../../../utils/SceneUtils";
import VectorUtils from "../../../utils/GeometryUtils/VectorUtils";
import MathUtils from "../../../utils/MathUtils";
import wallFunctionRules from "../../../../entities/wallFunctionCode/WallFunctionRules";
import WallManager from "../../graph/WallManager";
import { EPSILON } from "../../../consts";
import { soRoom2D } from "../Room/soRoom2D";
import { RoomEntityType } from "../../../../models/RoomEntityType";
import create from "@ant-design/icons/lib/components/IconFont";
import SegmentsUtils from "../../../utils/SegmentsUtils";
import BoundingBoxUtils from "../../../utils/GeometryUtils/BoundingBoxUtils";
import { soFloor2D } from "../Floor/soFloor2D";
import { Direction } from "../../Direction";
import WallSideInfo from "./WallSideInfo";
import RoomManagementTab from "../../../../ui/components/CorePlans/ModalSystemSettings/RoomManagementTab";
import { WallOffset } from "./WallOffset";
import RoomUtils from "../../../utils/RoomUtils";
import WallOverrides from "./wallOverrides";

/* ------------------------------------------------------------------------- */
/*                               Enums and Types                             */
/* ------------------------------------------------------------------------- */

/**
 * Indicates which wall end(s) to consider (start, end, or both).
 */
export enum WallEndType {
  start = "start",
  end = "end",
  both = "both",
}

/**
 * Indicates left or right sides of a wall.
 */
export enum WallSides {
  left = "left",
  right = "right",
}

/* ------------------------------------------------------------------------- */
/*                              Class Definition                             */
/* ------------------------------------------------------------------------- */

/**
 * Class representing a 2D wall in the scene.
 * Inherits from soGroup to allow handling multiple child components.
 */
export class soWall2D extends soGroup {
  /* ----------------------------------------------------------------------- */
  /*                           Public Properties                             */
  /* ----------------------------------------------------------------------- */

  /**
   * An array of IDs of rooms that the wall belongs to.
   */
  public parentRoomIds: string[] = [];

  /**
   * Wall sides information (left and right).
   */
  public WallSides: { left: WallSideInfo; right: WallSideInfo } = { left: undefined, right: undefined };

  /**
   * Array of openings (doors, windows, etc.) on this wall.
   */
  public openings: soOpening[] = [];

  /**
   * Optional reference to a line representing the wall centerline.
   */
  public wallCenterline: THREE.Line;

  /**
   * The side of the parent room (if any) that this wall belongs to.
   */
  public parentRoomSide: Side;

  /**
   * The side of the parent rooms (if any) that this wall belongs to.
   */
  public parentRoomSides: WallSideInfo[] = [];

  /**
   * Geometry objects that represent different parts of the wall.
   */
  public wallGeometry: THREE.Object3D[] = [];

  /**
   * The line representing the "location line" of the wall (for offset purposes).
   */
  public locationLine: THREE.Line;

  /**
   * Indicates if the wall is external (true) or internal (false).
   */
  public isExternal: boolean = true;

  /**
   * Indicates if the wall orientation is flipped.
   */
  public flipped: boolean = false;

  public wallOffset: WallOffset;

  /* ----------------------------------------------------------------------- */
  /*                           Private Properties                            */
  /* ----------------------------------------------------------------------- */
  /**
   * The function code representing construction details (e.g., 2x4 exterior).
   */
  private _functionCode: string = FuncCode.EXT_2X4;

  /**
   * Internal storage for the wall start point.
   */
  private _start: THREE.Vector2;

  /**
   * Internal storage for the wall end point.
   */
  private _end: THREE.Vector2;

  /**
   * Indicates if this wall should be rendered (has geometry).
   */
  private hasGeometry: boolean = true;

  private setFlipped(): void {
    if (this.parentRoomIds.length == 1 || !this.WallSides.left || !this.WallSides.right) {
      this.flipped = this.WallSides.right ? false : true;
    } else if (this.parentRoomIds.length == 2) {
      const room1 = this.WallSides.left.RoomCategoryName;
      const room2 = this.WallSides.right.RoomCategoryName;
      this.flipped = WallUtils.GetRoomCategoryNameTier(room1) < WallUtils.GetRoomCategoryNameTier(room2) ? true : false;
    }
  }

  /* ----------------------------------------------------------------------- */
  /*                                Constructor                              */
  /* ----------------------------------------------------------------------- */

  /**
   * Constructs an instance of soWall2D.
   * @param start - Start point of the wall.
   * @param end - End point of the wall.
   * @param hasGeometry - Boolean indicating if the wall has geometry.
   */
  constructor(start?: THREE.Vector2, end?: THREE.Vector2, hasGeometry: boolean = true) {
    super();
    // Optional error handling if you strictly require start/end:
    // if (!start || !end) {
    //   throw new Error("Start and end points must be defined.");
    // }
    // if (start && end && start.equals(end)) {
    //   throw new Error("Start and end points cannot be the same.");
    // }

    this._start = start;
    this._end = end;
    this._functionCode = FuncCode.EXT_2X4;
    this.CreateWallGeometry();
    this.hasGeometry = hasGeometry;
  }

  /* ----------------------------------------------------------------------- */
  /*                         Public Getters / Setters                        */
  /* ----------------------------------------------------------------------- */

  public getWallFunctionCode(): string | null {
    for (const rule of wallFunctionRules.rules) {
      const ruleClassifications: WallType[] = rule.conditions.classification;
      // Check if both arrays have the same length and the same elements (ignoring order)

      const classificationMatch =
        ruleClassifications.length === this.wallTypeTags.length &&
        ruleClassifications.every((cls: WallType) => this.wallTypeTags.includes(cls)) &&
        this.wallTypeTags.every((cls: WallType) => ruleClassifications.includes(cls));
      if (classificationMatch) {
        return rule.functionCode;
      }
    }
    // Return null if no rule matches
    return null;
  }

  public get FunctionCode(): string {
    return this._functionCode;
  }

  public setFunctionCode(value: string, updateGeometry = true): void {
    if (this._functionCode === value) return;
    this._functionCode = value;
    WallUtils.AddWallToOverideList(this);

    if (updateGeometry) {
      (this.parent as WallManager).updateGeometryByWallIds([this.wallId, ...this.getPerpendicularWallIds()]);
    }
  }
  /**
   * Gets the start point of the wall (cloned to protect original data).
   */
  public get start(): THREE.Vector2 {
    return this._start ? this._start.clone() : new THREE.Vector2();
  }

  /**
   * Gets the end point of the wall (cloned to protect original data).
   */
  public get end(): THREE.Vector2 {
    return this._end ? this._end.clone() : new THREE.Vector2();
  }

  /**
   * Returns a 2D bounding box for this wall.
   */
  public get BoundingBox2D(): THREE.Box3 {
    return GeometryUtils.getGeometryBoundingBox2D(this);
  }

  /**
   * Returns a 3D bounding box for this wall.
   */
  public get BoundingBox3D(): THREE.Box3 {
    return GeometryUtils.getGeometryBoundingBox3D(this);
  }

  public getTotalBoudingBox() {
    const bb = new THREE.Box3();
    this.children.forEach(child => {
      bb.expandByObject(child);
    });
    return bb;
  }

  /**
   * Returns the wall ID, generated from start and end points.
   */
  public get wallId(): string {
    return WallUtils.generateWallId(Vertex.generateVertexId(this.start), Vertex.generateVertexId(this.end));
  }

  /**
   * Indicates whether the wall is rendered or not.
   */
  public get HasGeometry(): boolean {
    return this.hasGeometry;
  }

  /**
   * Toggles the wall visibility and geometry presence.
   */
  public set HasGeometry(hasGeometry: boolean) {
    const oldHasGeometry = this.hasGeometry;
    this.hasGeometry = hasGeometry;
    this.visible = this.hasGeometry;
    //this.visible = false;
  }

  /**
   * Checks if the wall is vertical (difference in x is negligible).
   */
  public get isVertical(): boolean {
    return Math.abs(this.start.x - this.end.x) < 0.0001;
  }

  /**
   * Checks if the wall is horizontal (difference in y is negligible).
   */
  public get isHorizontal(): boolean {
    return MathUtils.areNumbersEqual(this.start.y, this.end.y);
  }

  /**
   * Calculates the direction (horizontal or vertical or unknown) of the wall.
   */
  public get wallDirection(): Direction {
    return GeometryUtils.getLineDirection(this.toLine3());
  }

  /**
   * Retrieves rooms that this wall belongs to.
   */
  public get ParentRooms(): soRoom2D[] {
    return (this.parent?.parent as soFloor2D)?.soRooms.filter(room => this.parentRoomIds.includes(room.soId));
  }

  /**
   * Gets the thickness on the right side of the wall (depends on flip state).
   */
  public get wallRightSideThickness(): number {
    const size = !appModel.showFinishFaceDimension
      ? catalogSettings.walls[this.FunctionCode].coreThickness
      : !this.flipped
        ? catalogSettings.walls[this.FunctionCode].interiorThickness
        : catalogSettings.walls[this.FunctionCode].exteriorThickness;
    return size;
  }

  /**
   * Gets the thickness on the left side of the wall (depends on flip state).
   */
  public get wallLeftSideThickness(): number {
    const size = !appModel.showFinishFaceDimension
      ? catalogSettings.walls[this.FunctionCode].coreThickness
      : this.flipped
        ? catalogSettings.walls[this.FunctionCode].interiorThickness
        : catalogSettings.walls[this.FunctionCode].exteriorThickness;
    return size;
  }
  public get coreSideThickness(): number {
    return catalogSettings.walls[this.FunctionCode].coreThickness;
  }
  /**
   * Gets the Vertex object for the start of this wall.
   */
  public get WallStartVertex(): Vertex {
    return (this.parent as WallManager).getVertexById(Vertex.generateVertexId(this.start));
  }

  /**
   * Gets the string ID of the start vertex of this wall.
   */
  public get WallStartVertexId(): string {
    return Vertex.generateVertexId(this.start);
  }

  /**
   * Gets the Vertex object for the end of this wall.
   */
  public get WallEndVertex(): Vertex {
    return (this.parent as WallManager).getVertexById(Vertex.generateVertexId(this.end));
  }

  /**
   * Gets the string ID of the end vertex of this wall.
   */
  public get WallEndVertexId(): string {
    return Vertex.generateVertexId(this.end);
  }

  /**
   * Gets the wall type tags (classification) for this wall.
   */
  public get wallTypeTags(): WallType[] {
    const wallTagsSet = new Set<WallType>();
    this.WallSides.left?.WallSideTypeTags.forEach(tag => wallTagsSet.add(tag));
    this.WallSides.right?.WallSideTypeTags.forEach(tag => wallTagsSet.add(tag));
    if (this.isExternal) wallTagsSet.add(WallType.EXT);
    else wallTagsSet.add(WallType.INT);

    return Array.from(wallTagsSet);
  }
  /* ----------------------------------------------------------------------- */
  /*                            Public Methods                               */
  /* ----------------------------------------------------------------------- */

  /**
   * returns a Cloned reversed wall instance.
   * @returns A new soWall2D instance.
   */
  public revert(): soWall2D {
    const copy = this.DeepCopy();
    const tmp = copy.start;
    copy._start = copy.end;
    copy._end = tmp;
    return copy;
  }
  public getRange(): number[] {
    const axis = this.isHorizontal ? "x" : "y";
    return [Math.min(this.start[axis], this.end[axis]), Math.max(this.start[axis], this.end[axis])];
  }
  /**
   * Sets the wall start point.
   * @param start - The new start point.
   */
  public setStart(start: THREE.Vector2): void {
    if (!start) {
      throw new Error("Start point must be defined.");
    }
    this._start = start;
    this.CreateWallGeometry();
  }

  /**
   * Sets the wall end point.
   * @param end - The new end point.
   */
  public setEnd(end: THREE.Vector2): void {
    if (!end) {
      throw new Error("End point must be defined.");
    }
    this._end = end;
    this.CreateWallGeometry();
  }

  /**
   * Returns the midpoint of the wall.
   */
  public getWallMidpoint(): THREE.Vector2 {
    return this._start.clone().add(this._end).multiplyScalar(0.5);
  }

  /**
   * Checks if the wall is axis-aligned (horizontal and left-to-right or vertical and bottom-to-top).
   * Suggestion: could be moved to a geometry utility.
   */
  public isAxisAligned(): boolean {
    const isHorizontal = this.isHorizontal;
    return (isHorizontal && this.start.x < this.end.x) || (!isHorizontal && this.start.y < this.end.y);
  }

  /**
   * Determines if a given point lies on this wall.
   * Suggestion: could be moved to a geometry utility.
   * @param point The point to check.
   * @param includeEdges Whether to include edge points.
   */
  public isPointOnWall(point: THREE.Vector2, includeEdges: boolean = true): boolean {
    if (!point) {
      throw new Error("Point must be defined.");
    }

    let result = false;
    if (this.isVertical) {
      if (Math.abs(point.x - this.start.x) > EPSILON) return false;
      const minY = Math.min(this.start.y, this.end.y);
      const maxY = Math.max(this.start.y, this.end.y);
      result = point.y >= minY && point.y <= maxY;
    } else {
      if (Math.abs(point.y - this.start.y) > EPSILON) return false;
      const minX = Math.min(this.start.x, this.end.x);
      const maxX = Math.max(this.start.x, this.end.x);
      result = point.x >= minX && point.x <= maxX;
    }

    return includeEdges ? result : result && !this.isPointOnWallEdges(point);
  }

  /**
   * Determines if a given point lies exactly on the edges (start or end) of this wall.
   * Suggestion: could be moved to a geometry utility.
   * @param point The point to check.
   */
  public isPointOnWallEdges(point: THREE.Vector2): boolean {
    if (!point) {
      throw new Error("Point must be defined.");
    }
    return Vertex.generateVertexId(point) === Vertex.generateVertexId(this.start) || Vertex.generateVertexId(point) === Vertex.generateVertexId(this.end);
  }

  /**
   * Checks if two walls are collinear.
   * Suggestion: could be moved to a geometry utility or to WallUtils.
   * @param wall Another wall to check.
   */
  public isWallCollinear(wall: soWall2D): boolean {
    if (!wall) {
      throw new Error("Wall must be defined.");
    }
    return WallUtils.areWallsCollinear(this, wall);
  }

  /**
   * Associates this wall with a set of rooms.
   * @param soRooms Array of rooms to link.
   */
  public setParentRooms(soRooms: soRoom2D[]): void {
    try {
      const originalParentRoomsNumber = this.parentRoomIds.length;
      soRooms.forEach(soRoom => (this.parentRoomIds.includes(soRoom.soId) ? null : this.addParentRoom(soRoom)));

      // Remove rooms that no longer exist in soRooms
      this.parentRoomIds.forEach(roomId => {
        if (!soRooms.map(r => r.soId).includes(roomId)) {
          this.removeParentRoomById(roomId);
        }
      });

      this.HasGeometry = this.determineHasGeometry();
      this.isExternal = this.parentRoomIds.length > 1 ? this.ParentRooms.some(room => !room.isIndoor) : this.parentRoomIds.length === 1;
      this._functionCode = WallUtils.generateWallFunctionCode(this);
      this.setFlipped();
    } catch (error) {
      console.warn("Failed to set parent rooms: " + error.message);
    }
  }

  public updateProperties(override: WallOverrides): void {
    try {
      if (override) {
        if (override.FunctionCode && !WallUtils.IsFunctionCodesFrameEqual(this.FunctionCode, override.FunctionCode))
          this.setFunctionCode(override.FunctionCode, false);
        if (override.Offset) {
          const side = override.Offset > 0 ? WallSides.left : WallSides.right;
          this.setWallOffset(new WallOffset(side, Math.abs(override.Offset)), false);
        }
      }
    } catch (error) {
      console.warn("Failed to update wall properties: " + error.message);
    }
  }

  public updateGeometry(): void {
    try {
      this.resetWallLocation(true);
      this.CreateWallGeometry();
    } catch (error) {
      console.warn("Failed to update wall geometry: " + error.message);
    }
  }

  /**
   * Determines whether the wall has geometry based on the parent rooms.
   */
  private determineHasGeometry(): boolean {
    if (this.parentRoomIds.length === 2) {
      // Create an array of boolean values indicating whether each parent room is indoor.
      const isIndoorFlags = this.ParentRooms.map(room => room.isIndoor);

      // If both parent rooms are outdoor, return false.
      if (isIndoorFlags.every(isIndoor => !isIndoor)) {
        return false;
      }
      // If exactly one of the parent rooms is indoor, return true.
      else if (isIndoorFlags.filter(isIndoor => isIndoor).length === 1) {
        return true;
      }
      // If neither of the above conditions is met (e.g., both are indoor),
      // determine HasGeometry based on whether every parent room has an optional wall for this wallId.
      else {
        return !this.ParentRooms.every(room => room.wallOptionalMap.get(this.wallId));
      }
    } else if (this.parentRoomIds.length === 1) {
      // If there is only one parent room, return true if it is indoor, otherwise true.
      return this.ParentRooms[0]?.isIndoor ?? true;
    }

    // Default return value if no specific conditions are met.
    return true;
  }

  /**
   * Adds a single parent room to this wall.
   * @param soRoom The room to add.
   */
  public addParentRoom(soRoom: soRoom2D): void {
    this.parentRoomIds.push(soRoom.soId);
    const wallOpeningLength = this.openings.length;

    const roomOpenings =
      soRoom.openings.length > 0
        ? soRoom.openings
        : (soRoom.children.filter(
            child => child instanceof soOpening || child.userData.type == RoomEntityType.Window || child.userData.type == RoomEntityType.Door
          ) as THREE.Object3D[]);

    roomOpenings.forEach(opening => {
      const openingBbox = GeometryUtils.getGeometryBoundingBox2D(opening);
      if (this.BoundingBox2D.intersectsBox(openingBbox)) {
        this.openings.push(opening as soOpening);
        //RoomUtils.openingSaveRoomLocation(opening as soOpening);
        opening.userData.parentWallId = this.wallId;
      }
    });
    const wallSide = WallUtils.getParentRoomWallSide(soRoom, this);
    this.WallSides[wallSide] = new WallSideInfo(soRoom, soRoom.wallsSideMap.get(this.wallId));

    const wallLine3 = this.toLine3();
    const roomDataBoxes = soRoom.dataBoxes;
    const dataBoxesLines = roomDataBoxes.map(dataBox => dataBox.dataBoxLines).flat();

    if (this.WallSides.right?.RoomId === soRoom.soId) {
      this.WallSides.right.addDataBoxClassification(wallLine3, dataBoxesLines, true);
    }

    if (this.WallSides.left?.RoomId === soRoom.soId) {
      this.WallSides.left.addDataBoxClassification(wallLine3, dataBoxesLines, false);
    }

    this.parentRoomSides.push(this.WallSides[wallSide]);

    // If you need to re-generate geometry whenever an opening is added, uncomment:
    // if (wallOpeningLength !== this.openings.length) {
    //   this.CreateWallGeometry();
    // }
  }

  /**
   * Removes a single parent room from this wall.
   * @param soRoom The room to remove.
   */
  public removeParentRoom(soRoom: soRoom2D): void {
    const wallOpeningLength = this.openings.length;
    this.WallSides[WallUtils.getParentRoomWallSide(soRoom, this)] = undefined;
    const idx = this.parentRoomIds.findIndex(id => id === soRoom.soId);
    if (idx !== -1) {
      this.parentRoomIds.splice(idx, 1);
      this.parentRoomSides.splice(idx, 1);
    }

    const roomOpenings =
      soRoom.openings.length > 0
        ? soRoom.openings
        : soRoom.children.filter(
            child => child instanceof soOpening || child.userData.type == RoomEntityType.Window || child.userData.type == RoomEntityType.Door
          );

    roomOpenings.forEach(opening => {
      const foundIdx = this.openings.findIndex(open => open.uuid === opening.uuid);
      if (foundIdx !== -1) {
        this.openings.splice(foundIdx, 1);
        opening.userData.parentWallId = "";
      }
    });
    this.resetWallLocation();
    // If you need to re-generate geometry whenever an opening is removed, uncomment:
    // if (wallOpeningLength !== this.openings.length) {
    //   this.CreateWallGeometry();
    // }
  }

  /**
   * Removes a parent room by its ID.
   * @param roomId The ID of the room to remove.
   * @throws Error if the room cannot be found.
   */
  public removeParentRoomById(roomId: string): void {
    const soFloor = this.parent?.parent as soFloor2D;
    const soRoom = soFloor?.soRooms.find(r => r.soId === roomId);

    if (soRoom) {
      this.removeParentRoom(soRoom);
    } else {
      console.log("Room not found");
      throw new Error(`Could not remove Room-${roomId} not found`);
    }
  }

  /**
   * Returns the center point of the wall.
   */
  public getCenter3(): THREE.Vector3 {
    return new THREE.Vector3(this.start.x + this.end.x, this.start.y + this.end.y).multiplyScalar(0.5);
  }
  /**
   * Creates the wall geometry, splitting for openings.
   * @throws Error if geometry creation fails.
   */
  public CreateWallGeometry(): void {
    this.CleanChildren();
    if (!this._start || !this._end) {
      return;
    }

    try {
      const extensionEnd = !this.parent ? 0 : WallUtils.getWallEdgeExtension(this.parent as WallManager, this, WallEndType.end);
      const extensionStart = !this.parent ? 0 : WallUtils.getWallEdgeExtension(this.parent as WallManager, this, WallEndType.start);

      let rightThickness = this.wallRightSideThickness;
      let leftThickness = this.wallLeftSideThickness;

      // If appModel is set to hide finish face dimension, override thickness to core thickness
      if (!appModel.showFinishFaceDimension) {
        rightThickness = leftThickness = catalogSettings.walls[this.FunctionCode].coreThickness;
      }

      const wallBBox =
        this.wallDirection === Direction.Horizontal
          ? new THREE.Box3(
              VectorUtils.Vector2ToVector3(this.start).add(new THREE.Vector3(-extensionStart, -rightThickness, 0)),
              VectorUtils.Vector2ToVector3(this.end).add(new THREE.Vector3(extensionEnd, leftThickness, 0))
            )
          : new THREE.Box3(
              VectorUtils.Vector2ToVector3(this.start).add(new THREE.Vector3(-leftThickness, -extensionStart, 0)),
              VectorUtils.Vector2ToVector3(this.end).add(new THREE.Vector3(rightThickness, extensionEnd, 0))
            );

      // Collect bounding boxes for the wall openings
      const openingBBoxes = this.openings.map(opening => GeometryUtils.getGeometryBoundingBox3D(opening));

      const splittedBoxes = this.openings.length > 0 ? BoundingBoxUtils.boundingBoxBooleanDifference(wallBBox.clone(), openingBBoxes) : [wallBBox];

      splittedBoxes.forEach(box => {
        // Example plane for further slicing if needed:
        const normal = this.isVertical ? new THREE.Vector3(0, 1, 0) : new THREE.Vector3(1, 0, 0);
        const plane = new THREE.Plane(normal, 0);

        // Create the geometry mesh(es) for each splitted box
        const wall = WallUtils.createSoWallGeometry(box, this);
        this.add(wall);
      });

      this.offsetWall();
      //this.visible = false;
    } catch (error) {
      throw new Error("Failed to create wall geometry: " + error.message);
    }
  }

  /**
   * Adds a wall classification (tag) to this wall if it does not exist yet.
   * @param wallType The wall classification to add.
   */
  public addWallClassifications(wallType: WallType): void {
    if (!this.wallTypeTags.includes(wallType)) {
      this.wallTypeTags.push(wallType);
    }
  }

  /**
   * Removes a wall classification (tag) from this wall.
   * @param wallType The wall classification to remove.
   */
  public removeWallClassifications(wallType: WallType): void {
    const idx = this.wallTypeTags.findIndex(tag => tag === wallType);
    if (idx !== -1) {
      this.wallTypeTags.splice(idx, 1);
    }
  }

  /**
   * Offsets the wall based on the location line.
   * In this minimal implementation, we shift the wall by 1 unit perpendicular to its direction.
   * @throws Error if the offset operation fails.
   */
  public OffsetWallByLocationLine(): void {
    try {
      if (!this.locationLine) {
        console.warn("Location line is not set. No offset performed.");
        return;
      }
      // Example minimal logic: offset by a fixed distance
      const offsetDistance = 1;
      const directionVector = new THREE.Vector2(this.end.y - this.start.y, this.start.x - this.end.x).normalize();
      this._start.addScaledVector(directionVector, offsetDistance);
      this._end.addScaledVector(directionVector, offsetDistance);

      // Re-create geometry after offset
      this.CreateWallGeometry();
    } catch (error) {
      throw new Error("Failed to offset wall by location line: " + error.message);
    }
  }

  /**
   * Sets the location line for the wall.
   * In this minimal implementation, we simply define the locationLine if not already defined.
   * @throws Error if setting the location line fails.
   */
  public SetLocationLine(): void {
    try {
      if (!this.locationLine) {
        // Create a basic line from start to end as the 'location line'
        const geometry = new THREE.BufferGeometry().setFromPoints([
          new THREE.Vector3(this.start.x, this.start.y, 0),
          new THREE.Vector3(this.end.x, this.end.y, 0),
        ]);
        this.locationLine = new THREE.Line(geometry, new THREE.LineBasicMaterial({ color: 0xff0000 }));
      }
    } catch (error) {
      throw new Error("Failed to set location line: " + error.message);
    }
  }
  public setWallOffset(offset: WallOffset, updateGeometry = true): void {
    this.wallOffset = offset;
    WallUtils.AddWallToOverideList(this);
    if (updateGeometry) {
      (this.parent as WallManager).updateGeometryByWallIds([this.wallId, ...this.getPerpendicularWallIds()]);
    }
  }
  /**
   * Gets the wall width.
   * In this minimal implementation, we simply return the difference between left and right thickness.
   * @returns The width of the wall.
   * @throws Error if the wall width cannot be determined.
   */
  public GetWallTotalWidth(coreOnly: boolean): number {
    try {
      return coreOnly ? this.coreSideThickness * 2 : this.wallRightSideThickness + this.wallLeftSideThickness;
    } catch (error) {
      throw new Error("Failed to get wall width: " + error.message);
    }
  }

  /**
   * Gets IDs of walls perpendicular to this wall (from a given end or both ends).
   * @param from Which wall end(s) to check.
   */
  public getPerpendicularWallIds(from: WallEndType = WallEndType.both): string[] {
    const wallIds: string[] = [];
    const verts: Vertex[] = [];
    if (!this.WallStartVertex || !this.WallEndVertex) return wallIds;
    try {
      if (from === WallEndType.both || from === WallEndType.start) {
        verts.push(this.WallStartVertex);
      }
      if (from === WallEndType.both || from === WallEndType.end) {
        verts.push(this.WallEndVertex);
      }

      for (const vertex of verts) {
        if (this.wallDirection === Direction.Horizontal) {
          if (vertex.edges?.top) wallIds.push(vertex.edges.top);
          if (vertex.edges?.bottom) wallIds.push(vertex.edges.bottom);
        } else {
          if (vertex.edges?.left) wallIds.push(vertex.edges.left);
          if (vertex.edges?.right) wallIds.push(vertex.edges.right);
        }
      }
    } catch (e) {
      console.log(e);
    }
    return wallIds;
  }

  /**
   * Converts this wall to a THREE.Line3 object, ignoring the Z-axis.
   */
  public toLine3(): THREE.Line3 {
    return new THREE.Line3(new THREE.Vector3(this.start.x, this.start.y, 0), new THREE.Vector3(this.end.x, this.end.y, 0));
  }

  /**
   * Returns the length of the wall (distance from start to end).
   */
  public length(): number {
    return this.start.distanceTo(this.end);
  }

  /**
   * Returns a vector representing the difference (end - start).
   */
  public delta(): THREE.Vector2 {
    return this.end.clone().sub(this.start);
  }
  /**
   * Moves the wall's children and openings by adding a provided vector.
   * @param {THREE.Vector3} vector - The vector by which to move the wall geometry and its children.
   */
  public moveWallGeometryAndChildren(vector: THREE.Vector3): void {
    if (!vector) {
      console.error("Invalid vector provided for movement.");
      return;
    }
    this.children.forEach(child => {
      if (child.position) {
        child.position.add(vector.clone());
      }
    });
    this.openings.forEach(opening => RoomUtils.offsetOpening(opening, vector.clone()));
  }

  /**
   * Resets the wall location to its original position based on the offset vector.
   */
  public resetWallLocation(openingsOnly = false): void {
    this.openings.forEach(opening => {
      RoomUtils.offsetOpening(opening, new THREE.Vector3());
    });
    if (openingsOnly || !this.wallOffset) return;
    this.wallOffset = null;
    (this.parent as WallManager).updateGeometryByWallIds([this.wallId, ...this.getPerpendicularWallIds()], true, false);
    this.updateGeometry();
  }

  /**
   * Applies the wall offset by moving the wall geometry and shortening connected perpendicular walls.
   */
  public offsetWall(reverse: boolean = false): void {
    const { wallOffset } = this;
    if (!wallOffset?.direction) return;

    const offsetVector = this.getOffsetVector();
    if (!offsetVector) {
      console.error("Failed to calculate offset vector for wall offset.");
      return;
    }
    if (reverse) offsetVector.negate();
    this.moveWallGeometryAndChildren(offsetVector);

    const perpendicularWallIds = this.getPerpendicularWallIds();
    const wallManager = this.parent as WallManager;
    wallManager.updateGeometryByWallIds(perpendicularWallIds);

    const cloneWall = (wall: soWall2D): soWall2D => {
      const cloned = Object.create(Object.getPrototypeOf(wall));
      Object.assign(cloned, wall);
      return cloned;
    };

    const getShortenBy = (wallDir: Direction): number => {
      if (wallDir === Direction.Vertical) {
        return wallOffset.direction === WallSides.right ? this.wallLeftSideThickness : this.wallRightSideThickness;
      } else if (wallDir === Direction.Horizontal) {
        return wallOffset.direction === WallSides.left ? this.wallLeftSideThickness : this.wallRightSideThickness;
      }
      return 0;
    };

    wallManager.getWallsByIds(perpendicularWallIds).forEach(perWall => {
      const { wallDirection: wallDir } = perWall;

      if (wallDir !== Direction.Horizontal && wallDir !== Direction.Vertical) return;

      const shortenBy = getShortenBy(wallDir);
      if (shortenBy === 0) return;

      const direction = perWall.end.clone().sub(perWall.start).normalize();
      const shrink = direction.multiplyScalar(shortenBy);

      let newStart = perWall.start.clone();
      let newEnd = perWall.end.clone();

      // Determine the correct side to shrink from
      if (wallOffset.direction === WallSides.left) {
        if (perWall.end.y > perWall.start.y) {
          newEnd = newEnd.sub(shrink);
        } else {
          newStart = newStart.add(shrink);
        }
      } else if (wallOffset.direction === WallSides.right) {
        if (perWall.end.x > perWall.start.x) {
          newEnd = newEnd.sub(shrink);
        } else {
          newStart = newStart.add(shrink);
        }
      }

      const wallBBox = new THREE.Box3(VectorUtils.Vector2ToVector3(newStart), VectorUtils.Vector2ToVector3(newEnd));

      this.remove(perWall);

      const newWallInstance = cloneWall(perWall);
      newWallInstance.setStart(newStart);
      newWallInstance.setEnd(newEnd);

      const newWall = WallUtils.createSoWallGeometry(wallBBox, newWallInstance);
      this.add(newWall);
    });
  }

  getOffsetValue(): number {
    const vec = this.getOffsetVector();
    return !vec ? 0 : Math.abs(vec.x) < EPSILON ? vec.y : vec.x;
  }
  /**
   * Computes the offset vector based on the wall's offset properties.
   * @param {boolean} reverse - If true, calculates the reverse of the current offset vector.
   * @returns {THREE.Vector3|null} The computed offset vector, or null if computation fails.
   */
  public getOffsetVector(reverse: boolean = false): THREE.Vector3 | null {
    if (!this.wallOffset) {
      return new THREE.Vector3();
    }

    try {
      const offset = reverse ? this.wallOffset.Reversed : this.wallOffset;
      const deltaVector = this.delta();

      if (!deltaVector) {
        console.error("Delta vector is undefined or null.");
        return null;
      }

      const { right, left } = VectorUtils.rotateVector(VectorUtils.Vector2ToVector3(deltaVector.normalize()));
      const offsetVector = offset.direction === WallSides.left ? left : right;
      offsetVector.multiplyScalar(offset.distance);
      return offsetVector;
    } catch (error) {
      console.error("Error while calculating offset vector:", error);
      return null;
    }
  }
  /**
   * Creates a deep copy of this soWall2D instance.
   * @returns A new soWall2D instance with copied properties.
   */
  public DeepCopy(): soWall2D {
    const copy = this.clone() as soWall2D;

    copy.setStart(this.start.clone());
    copy.setEnd(this.end.clone());

    copy.parentRoomIds = [...this.parentRoomIds];
    copy.parentRoomSides = [...this.parentRoomSides];
    copy._functionCode = this._functionCode;
    // Depending on your logic, you might want to deep copy each opening:
    // copy.openings = this.openings.map((opening) => opening.DeepCopy());
    copy.openings = this.openings;

    copy.wallCenterline = this.wallCenterline?.clone();
    copy.parentRoomSide = this.parentRoomSide;
    copy.wallGeometry = this.wallGeometry.map(geometry => geometry.clone());
    copy.locationLine = this.locationLine?.clone();
    copy.isExternal = this.isExternal;
    copy.flipped = this.flipped;
    return copy;
  }

  /**
   * Calculates the wall thickness based on THIS wall side.
   * @param {soRoom2D} soRoom - The room for which the wall side will be determined.
   * @returns {number} The thickness of this wall - wether it's left or right thickness.
   */
  public getWallThicknessForRoom(soRoom: soRoom2D): number {
    let thickness = 0;
    const wallSide = this.getRelativeWallSide(soRoom);
    if (wallSide === "left" || wallSide === "top") {
      thickness = this.wallRightSideThickness;
    } else if (wallSide === "right" || wallSide === "bottom") {
      thickness = this.wallLeftSideThickness;
    }
    return thickness;
  }

  /**
   * Calculates the wall offset based on THIS wall side.
   * @param {soRoom2D} soRoom - The room for which the wall side will be determined.
   * @returns {number} The offset of this wall - wether it's left or right wall side.
   */
  public getWallOffsetForRoom(soRoom: soRoom2D): number {
    let direction = 1;
    let wallSide: WallSides;
    const roomSide = this.getRelativeWallSide(soRoom);
    if (roomSide === "left" || roomSide === "top") {
      wallSide = WallSides.right;
    } else if (roomSide === "right" || roomSide === "bottom") {
      wallSide = WallSides.left;
    }

    if (this.wallOffset) direction = this.wallOffset.direction == wallSide ? -1 : 1;
    const delta = Math.abs(this.getOffsetValue()) * direction;

    return delta;
  }

  /**
   * Determines the side of THIS wall relative to the given room - THIS wall can be outside of the room.
   * @param {soRoom2D} soRoom - The room object containing the walls.
   * @returns {string} - The side of the wall, which can be "left", "right", "bottom", "top", or "unknown".
   */
  public getRelativeWallSide(soRoom: soRoom2D): string {
    const roomCenter = BoundingBoxUtils.getBoundingBoxCenter(soRoom.boundingBoxByModelLine);
    if (this.isHorizontal) {
      return this.start.y < roomCenter.y ? "bottom" : "top";
    } else if (this.isVertical) {
      return this.start.x < roomCenter.x ? "left" : "right";
    } else {
      return "unknown";
    }
  }
}
